<template>
  <div class="pamodzi-black-backgropund text-white">
    <div class="pt-md-5 pb-3 pt-3 pl-sm-3 container background">
      <div class="row">
        <h2 class="col-12 font-weight-bold pamodzi-red">
          HISTORICAL KEY ACQUISITIONS
        </h2>
      </div>
      <div class="row mt-2 ">
        <div class="col-12   my-auto">
          <h3 class="font-weight-bold pt-md-0 pt-3 ">Food Corp</h3>
          <p class="pt-md-5 pt-3 ">
            In 1998 Pamodzi purchased 51% of food manufacturing, marketing and
            distribution company FoodCorp for R1.9 billion, giving it control of
            leading brands such as Yum Yum peanut butter, Ouma Rusks, Nola
            mayonnaise and Glenryck canned pilchards. This becomes the largest
            management buyout in South Africa.
          </p>
        </div>
        <hr />
      </div>
      <hr />
      <div class="row mt-2">
        <div class="col-12   my-auto order-md-0 order-1">
          <h3 class="font-weight-bold pt-md-5 pt-3 ">Kulungile Metals</h3>
          <p class="pt-md-5 pt-3 ">
            In 2003, the company acquired 30% of world-class stockist,
            distributor and processor of stainless steel Kulungile Metals (Pty)
            Ltd, formerly known as Stalcor.
          </p>
        </div>
        <!-- <div class="col-md-6 my-auto order-md-1 order-0">
        <div class="position-relative">
          <img src="../../assets/home/kulungileMetals.jpg" class="img-fluid position-relative aquisitionsBoxShadow"
               alt="BMW Acquisition 1">
        </div>
      </div> -->
      </div>
      <hr />

      <div class="row mt-2">
        <!-- <div class="col-md-6 my-auto">
        <div class="position-relative">
          <img src="../../assets/home/coal.png" class="img-fluid position-relative aquisitionsBoxShadow"
               alt="Anglo Coal Image">
        </div>
      </div> -->
        <div class="col-12   my-auto">
          <h3 class="font-weight-bold pt-md-5 pt-3 ">Anglo Coal</h3>
          <p class="pt-md-5 pt-3 ">
            In 1998, subsidiary Pamodzi Coal purchased a 26% stake in Anglo
            Coal, through the broad-based black economic empowerment consortium
            Inyosi. The resulting R7.4 billion company, Anglo Inyosi Coal, is a
            fully empowered South African company with a resource base of over
            four billion tons of coal.
          </p>
        </div>
      </div>
      <hr />

      <div class="row mt-2">
        <div class="col-12   my-auto order-md-0 order-1">
          <h3 class="font-weight-bold pt-md-5 pt-3 ">Digicore</h3>
          <p class="pt-md-5 pt-3 ">
            In 1999,Pamodzi Investment Holdings acquired 26% of Digicore,
            the JSE-listed vehicle tracking and fleet management products
            supplier.
          </p>
        </div>
        <!-- <div class="col-md-6 my-auto order-md-1 order-0">
        <div class="position-relative">
          <img src="../../assets/home/logistics.jpg" class="img-fluid position-relative aquisitionsBoxShadow"
               alt="BMW Acquisition 1">
        </div>
      </div> -->
      </div>
      <hr />

      <div class="row mt-2">
        <!-- <div class="col-md-6 my-auto ">
        <div class="position-relative">
          <img src="../../assets/home/bmw.jpeg" class="img-fluid position-relative aquisitionsBoxShadow"
               alt="BMW Acquisition 1">
        </div>
      </div> -->
        <div class="col-12   my-auto">
          <h3 class="font-weight-bold pt-md-5 pt-3 ">Auto Bavaria Midrand</h3>
          <p class="pt-md-5 pt-3 ">
            Auto Bavaria Midrand became the first black empowered Dealership in
            South Africa when it was purchased from BMW South Africa in 1998 by
            Andre Dreyer. Pamodzi Investment Holdings became the partners to
            Andre Dreyer in 1999.
          </p>
        </div>
      </div>
      <hr />

      <div class="row mt-2">
        <div class="col-12   my-auto order-md-0 order-1">
          <h3 class="font-weight-bold pt-md-5 pt-3 ">Nike Inc</h3>
          <p class="pt-md-5 pt-3 ">
            In 1993, Pamodzi Founders Ndaba Ntsele and Solly Sithole were
            awarded the Nike license in preparation for Nike’s reinvestment into
            South Africa. Ndaba and his team exceeded return on investment for
            eight consecutive years before selling back the licensing business
            to Nike.
          </p>
        </div>
        <!-- <div class="col-md-6 my-auto order-md-1 order-0">
        <div class="position-relative">
          <img src="../../assets/home/nike.jpg" class="img-fluid position-relative aquisitionsBoxShadow"
               alt="Nike Image">
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeKeyAquisitions",
};
</script>

<style scoped>
hr {
  background-color: white;
}
</style>
