<template>
  <div class="bg-light">
    <div class="container h-100 p-5">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="form-group">
          <blockquote class="blockquote text-center">
            <p class="mb-0 font-italic">"{{selectedQuote.quote}}"</p>
            <footer class="blockquote-footer">{{selectedQuote.by}}
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Quotes",
  data() {
    return {
      quotes:  [
        {
          quote: "Strive for perfection in everything you do. Take the best that exists and make it better. When it does not exist, design it",
          by: "Sir Henry Royce"
        },
        {
          quote: "There are three things in the world that deserve no mercy: Hypocrisy, Fraud and Tyranny",
          by: "Frederick William Robertson"
        },        {
          quote: "To err is human;to admit it is superhuman",
          by: "Doug Larson"
        },        {
          quote: "Having a good discussion is like having riches",
          by: "Kenyan Proverb"
        },        {
          quote: "Patience is wisdom of solving all problems",
          by: "Sudanese Proverb"
        },        {
          quote: "Hope is the only thing stronger than fear",
          by: "Robert Ludlum"
        },        {
          quote: "We need more people that specialize in the impossible",
          by: "Theodore Roethke"
        },
        {
          quote: "Every job looks easy when you are not doing it",
          by: "Jeff Immelt"
        },        {
          quote: "Darkness cannot drive out darkness; only light can do that. Hate cannot drive out hate; only love can do that",
          by: "Martin Luther King, Jr."
        },        {
          quote: "You are beautiful, but learn to work because you cannot eat your beauty",
          by: "African Proverb"
        },        {
          quote: "Success is not final;failure is not fatal: it is the courage to continue that counts",
          by: "Winston S. Churchhill"
        },
      ],
      selectedQuote: {}
    }
  },
  mounted() {
    this.selectedQuote = this.quotes[Math.floor(Math.random()* this.quotes.length)]
  }
}
</script>

<style scoped>

</style>
