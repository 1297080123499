<template>
  <div class="home">
    <NavATF route-id="home" class="d-lg-none"></NavATF>
    <HomeATF class="d-none d-lg-block" ></HomeATF>
<!--    <HomeAbout id="HomeAbout" class=""></HomeAbout>-->
<!--    <HomeVideo class="py-5"></HomeVideo>-->
    <HomeKeyAquisitions></HomeKeyAquisitions>
    <SubsidiariesMore></SubsidiariesMore>
    <Quotes></Quotes>
    <contact-us></contact-us>
  </div>
</template>

<script>
// @ is an alias to /src

import HomeKeyAquisitions from "@/components/home/HomeKeyAquisitions";
import Quotes from "@/components/shared/Quotes";
import SubsidiariesMore from "@/components/subsidiaries/SubsidiariesMore";
import ContactUs from "@/components/contact/ContactUs";
import HomeATF from "@/components/home/HomeATF";
import NavATF from "@/components/shared/NavATF";

export default {
  name: 'Home',
  components: {NavATF, HomeATF, ContactUs, SubsidiariesMore, Quotes, HomeKeyAquisitions},
  created() {
    document.title = "Pamodzi | Home"
  }
}
</script>

