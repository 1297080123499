<template>
  <div class="pamodzi-cream-backgropund">
    <div class="text-md-center p-md-3 p-3 container background">
      <h2 class="p-md-5 pb-3 pb-md-0 font-weight-bold pamodzi-red">ABOUT US</h2>
      <p class="px-md-5">Pamodzi stands for “Togetherness” in an African sense. To us Pamodzi presents collective
        growth; the ripple and
        multiplying effect that comes with working together for success by combining the diverse abilities of our people
        to achieve a bigger goal.</p>
      <router-link to="/about">
        <button class="btn mt-md-5 mt-3 rounded-pill px-3 text-white pamodzi-red-background">
          Learn more
        </button>
      </router-link>
      <a class="btn mt-md-5 mt-3 rounded-pill px-3 ml-3 text-white pamodzi-red-background" href="/files/PamodziProfile.pdf" target="_blank" v-on:click="downloadProfile()">
        Company Profile
      </a>
<!--      <router-link to="/subsidiaries/subsidiaries">-->
<!--        <button class="btn mt-md-5 mt-3 mx-3 rounded-pill px-3 text-white pamodzi-red-background">-->
<!--          Subsidiaries-->
<!--        </button>-->
<!--      </router-link>-->

    </div>
    <HomeVideo id="video" class="p-0 pb-5"></HomeVideo>
  </div>
</template>

<script>


import HomeVideo from "@/components/home/HomeVideo";
export default {
  name: "HomeAbout",
  components: {HomeVideo},
  methods: {
    scrollToVideo(){
      document.getElementById('video').scrollIntoView({behavior: 'smooth', block: 'center'})
    },
showTaC: function(){
  var pdf = '"/src/assets/files/PamodziProfile.pdf';
  window.open(pdf);
}

  }

}
</script>

<style scoped>
.background{
  background-position: 20px;
  background-image: url("../../assets/images/shared/backgroundLogo.svg");
  background-repeat: no-repeat;
}
</style>
