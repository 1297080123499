<template>
  <div class="container-fluid background">
    <div  class="form">
      <div class="row pamodzi-red-background">
        <div class="col-md-6 p-0">
          <img src="../../assets/images/contact/oldBuilding.png" class="img-fluid w-100" alt="Pamodzi Building">
        </div>
        <div class="col-md-6 my-auto background py-5  pamodzi-cream">
          <h1 class="atf-heading pa text-uppercase mt-md-n0 mt-n4">Contact Us</h1>
          <div class="row py-3">
            <div class="col-md-6">
              <address>
                Physical Address : <br>
                <div class="pl-3">
                  70 Grayston Dr <br>
                  Sandown <br>
                  Sandton <br>
                  2031
                </div>
              </address>
            </div>
            <div class="col-md-6">
              <address>
                Postal Address: <br>
                <div class="pl-3">
                  Postnet Suite 77 <br>
                  Private Bag X1 <br>
                  Melrose Arch 2076 <br>
                  South Africa
                </div>
              </address>
            </div>
          </div>
          <div class="row pb-3 text-decoration-none">
            <div class="col-md-6">
              <a id="number" class="pamodzi-cream" href="tel:+27100722000">+27 10 072 2000</a>
            </div>
            <div class="col-md-6">
              <a id="emailAddress" class="pamodzi-cream" href="mailto:info@pamodzi.co.za">info@pamodzi.co.za</a>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="firstName">Name</label>
              <input type="text" v-model="firstName" class="form-control"  name="firstName" id="firstName" placeholder="Name">
            </div>
            <div class="form-group col-md-6">
              <label for="lastName">Surname</label>
              <input type="text" v-model="lastName" class="form-control" name="lastName" id="lastName" placeholder="Surname">
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="text" v-model="email" class="form-control" name="email" id="email" placeholder="Email Address">
          </div>
          <div class="form-group">
            <label for="message">Message</label>
            <textarea type="text" v-model="message" class="form-control" cols="10" name="message" id="message" placeholder="Message"></textarea>
          </div>
          <button @click="checkForm()" class="btn rounded-pill font-weight-bolder pamodzi-cream-backgropund pamodzi-red">
            Contact Us
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;

export default {
  name: "ContactForm",
  data(){
    return {
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    } 
  },
  methods: {
        checkForm: function () {
      if (this.firstName && this.lastName && this.email && this.message) {

          axios.post('http://localhost/pamodzi/sendEmail.php', {
            name: this.firstName,
            email:this.email,
            message: this.message
          })
          .then((response) => {
            this.feedData = response.data.channel.item;
            this.logResults(this.feedData);
          }, (err) => {
            console.log('the following error occured: ', err);
          })
        // return true;
      }

      this.errors = [];

      if (!this.firstName) {
        this.errors.push('Name Field is required.');
      }
      if (!this.lastName) {
        this.errors.push('Surname Field is required.');
      }
      if (!this.email) {
        this.errors.push('Email Field is required.');
      }
      if (!this.message) {
        this.errors.push('Message Field is required.');
      }
      console.log(this.errors);
      console.log(document.getElementById('formMail'));
    }
  }
};
</script>

<style scoped lang="scss">
.background {
  background-position: 20px;
  background-image: url("../../assets/images/shared/backgroundLogo.svg");
  background-repeat: no-repeat;
}

a{
  &:hover {
    color: #d0d0d0;
  }
}

img{
  object-fit: cover;
  height: 100%;
}
</style>
