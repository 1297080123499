<template>
  <div class="pamodzi-cream-backgropund">
    <div class="container">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/u43EV6_676E"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeVideo",
};
</script>

<style scoped lang="scss">
$color-button: #fff;
$color-button-hover: #f00;

.videoWrapper {
  position: relative;
  width: 100%;
  height: 0;
  background-color: #000;

  &43 {
    padding-top: 75%;
  }

  &169 {
    padding-top: 56%;
  }
}

.videoIframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.videoPoster {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  outline: none;
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-size: cover;
  text-indent: -999em;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: opacity 800ms, height 0s;
  -moz-transition: opacity 800ms, height 0s;
  transition: opacity 800ms, height 0s;
  -webkit-transition-delay: 0s, 0s;
  -moz-transition-delay: 0s, 0s;
  transition-delay: 0s, 0s;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    margin: -40px 0 0 -40px;
    border: 5px solid $color-button;
    border-radius: 100%;
    -webkit-transition: border-color 300ms;
    -moz-transition: border-color 300ms;
    transition: border-color 300ms;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    margin: -20px 0 0 -10px;
    border-left: 40px solid $color-button;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    -webkit-transition: border-color 300ms;
    -moz-transition: border-color 300ms;
    transition: border-color 300ms;
  }

  &:hover,
  &:focus {
    &:before {
      border-color: $color-button-hover;
    }

    &:after {
      border-left-color: $color-button-hover;
    }
  }

  .videoWrapperActive & {
    opacity: 0;
    height: 0;
    -webkit-transition-delay: 0s, 800ms;
    -moz-transition-delay: 0s, 800ms;
    transition-delay: 0s, 800ms;
  }
}

// MISC STUFF FOR THIS EXAMPLE

body {
  font-family: avenir, sans-serif;
}

main {
  max-width: 800px;
  margin: 20px auto;
}
</style>
