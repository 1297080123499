<template>
    <div class="pamodzi-black-backgropund opacity-90">
      <div class="container pt-3">
        <div class="row text-center">
          <div class="col-md-12 pamodzi-cream font-weight-light">
            <p>
              Copyright {{ getCurrentYear() }} © Pamodzi. All rights reserved. | Terms and Condition. | Privacy Policy
              | Website Designed
              by <span><a
                href="https://insync.co.za" target="_blank">Insync-SA</a></span>
            </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "footer",
  methods: {
    getCurrentYear: function () {
      return new Date().getFullYear();
    }
  },
}
</script>

<style scoped lang="scss">
a {
  color: #d0d0d0;

  &:hover {
    color: #d0d0d0;
  }
}

.list-inline {
  display: inline-flex;
}

.list-inline li {
  flex: 1;
  text-align: center;
}

* {
  font-size: 16px;
}
</style>
