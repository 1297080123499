<template>
  <div class="bg-light">
    <div class="container py-md-5 py-3 ">
      <div class="row my-md-3 my-1">
        <div class="col-md-6">
          <div class="info">
            <h5 class="header">
              Our Investment Philosophy
            </h5>
            <p class="font-weight-light small-font">
              In line with our brand ethos of using the spirit of business to
              liberate people at corporate level, we use the very same spirit to
              liberate industries. We strive to be a dominant player in each of
              the industries we operate in through the ownership of companies
              that are leaders in those sectors.
            </p>
            <p class="font-weight-light small-font">
              Swipe right to see more
            </p>
          </div>
        </div>
        <div class="col-md-6"></div>
      </div>
      <div class="scroll-x">
        <div class="row">
          <div
            class=" col-md-4 col-12 py-md-0 pb-2"
            v-if="item !== 'Pamodzi Unique Engineering'"
          >
            <router-link to="/subsidiaries/Pamodzi Unique Engineering">
              <div class="p-1">
                <div class="sub-item">
                  <div class="img">
                    <img
                      src="../../assets/images/subsidiaries/pamodziGroupLogos/unique.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="caption d-flex">
                    <div>
                      <span class="gi-01"></span>Pamodzi Unique Engineering
                    </div>
                    <div class="gi-01">></div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class=" col-md-4 col-12 py-md-0 pb-2"
            v-if="item !== 'Pamodzi Smart'"
          >
            <router-link to="/subsidiaries/Pamodzi Smart">
              <div class="p-1">
                <div class="sub-item mt-md-0">
                  <div class="img">
                    <img
                      src="../../assets/images/subsidiaries/pamodziGroupLogos/smart.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="caption d-flex">
                    <div><span class="gi-02"></span>Pamodzi Smart Store</div>
                    <div class="gi-02">></div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class=" col-md-4 col-12 py-md-0 pb-2"
            v-if="item !== 'Pamodzi Petroleum'"
          >
            <router-link to="/subsidiaries/Pamodzi Petroleum">
              <div class="p-1">
                <div class="sub-item mt-md-0">
                  <div class="img">
                    <img
                      src="../../assets/images/subsidiaries/pamodziGroupLogos/petroleum.jpg"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="caption d-flex">
                    <div><span class="gi-03"></span>Pamodzi Petroleum</div>
                    <div class="gi-03">></div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class=" col-md-4 col-12 py-md-0 pb-2"
            v-if="item !== 'Pamodzi Pannonia'"
          >
            <router-link to="/subsidiaries/Pamodzi Pannonia">
              <div class="p-1">
                <div class="sub-item">
                  <div class="img">
                    <img
                      src="../../assets/images/subsidiaries/pamodziGroupLogos/pannonia.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="caption d-flex">
                    <div><span class="gi-04"></span>Pamodzi Pannonia</div>
                    <div class="gi-04">></div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class=" col-md-4 col-12 py-md-0 pb-2"
            v-if="item !== 'Pamodzi Technologies'"
          >
            <router-link to="/subsidiaries/Pamodzi Technologies">
              <div class="p-1">
                <div class="sub-item">
                  <div class="img">
                    <img
                      src="../../assets/images/subsidiaries/pamodziGroupLogos/tech.jpg"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="caption d-flex">
                    <div><span class="gi-07"></span>Pamodzi Technologies</div>
                    <div class="gi-07">></div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class=" col-md-4 col-12 py-md-0 pb-2"
            v-if="item !== 'Pamodzi Green Energy'"
          >
            <router-link to="/subsidiaries/Pamodzi Green Energy">
              <div class="p-1">
                <div class="sub-item mt-md-0">
                  <div class="img">
                    <img
                      src="../../assets/images/subsidiaries/pamodziGroupLogos/greenEnergy.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="caption d-flex">
                    <div><span class="gi-08"></span>Pamodzi Green Energy</div>
                    <div class="gi-08">></div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class=" col-md-4 col-12 py-md-0 pb-2"
            v-if="item !== 'Pamodzi Resources'"
          >
            <router-link to="/subsidiaries/Pamodzi Resources">
              <div class="p-1">
                <div class="sub-item">
                  <div class="img">
                    <img
                      src="../../assets/images/subsidiaries/pamodziGroupLogos/resources.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="caption d-flex">
                    <div><span class="gi-09"></span>Pamodzi Resources</div>
                    <div class="gi-09">></div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class=" col-md-4 col-12 py-md-0 pb-2"
            v-if="item !== 'Pamodzi FAW + Fleet Management'"
          >
            <router-link
              to="/subsidiaries/Pamodzi FAW + Fleet Management"
            >
              <div class="p-1">
                <div class="sub-item">
                  <div class="img">
                    <img
                      src="../../assets/images/subsidiaries/pamodziGroupLogos/fawLogo.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="caption d-flex">
                    <div>
                      <span class="gi-10"></span>Pamodzi FAW/ Fleet Management
                    </div>
                    <div class="gi-10">></div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class=" col-md-4 col-12 py-md-0 pb-2"
            v-if="item !== 'Pamodzi rail'"
          >
            <router-link to="/subsidiaries/Pamodzi rail">
              <div class="p-1">
                <div class="sub-item mt-md-0">
                  <div class="img">
                    <img
                      src="../../assets/images/subsidiaries/pamodziGroupLogos/rail.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="caption d-flex">
                    <div><span class="gi-11"></span>Pamodzi Rail</div>
                    <div class="gi-11">></div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class=" col-md-4 col-12 py-md-0 pb-2"
            v-if="item !== 'Phephani Tyre Tech'"
          >
            <router-link to="/subsidiaries/Phephani Tyre Tech">
              <div class="p-1">
                <div class="sub-item mt-md-0">
                  <div class="img">
                    <img
                      src="../../assets/images/subsidiaries/pamodziGroupLogos/phephani.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="caption d-flex">
                    <div><span class="gi-12"></span> Phephani Tyre Tech</div>
                    <div class="gi-12">></div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div
            class=" col-md-4 col-12 py-md-0 pb-2"
            v-if="item !== 'Phephani Tyre Tech'"
          >
            <router-link to="/subsidiaries/PRES">
              <div class="p-1">
                <div class="sub-item mt-md-0">
                  <div class="img">
                    <img
                      src="../../assets/images/subsidiaries/pamodziGroupLogos/pres.png"
                      alt=""
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="caption d-flex">
                    <div><span class="gi-02"></span> PRES </div>
                    <div class="gi-02">></div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubsidiariesMore",
  data() {
    return {
      item: "",
    };
  },
  watch: {
    "$route.params": {
      handler: function(params) {
        console.log(params);
        if (this.$route.name === "subsidiaries" && params.id) {
          console.log(params.id);
          this.item = params.id;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.info {
  text-align: left;
  .header {
    font: normal normal normal 15px/20px Open Sans;
  }

  .small-font {
    font-size: 15px;
  }
}
.scroll-x > .row {
  overflow-x: scroll;
  flex-wrap: nowrap;
  scroll-padding-left: auto;
}
.scroll > .row > .col-4 {
  display: inline-block;
}
::-webkit-scrollbar {
  //   width: 0;
  background: transparent;
}
</style>
