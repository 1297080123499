<template>
  <div class="position-relative vh-100 largeATF">
    <!--    <img src="../../assets/images/contact/oldBuilding.png" alt="" class="img-fluid w-100 position-relative vh-70">-->
    <div class="container-fluid">
      <div class="text row w-100">
        <div class="col-md-6">
          <h1 class="text-light heading" id="header">
            THE POWER OF BUSINESS
          </h1>
          <p class="width-80 small font-weight-light pt-5 pamodzi-cream">
            At Pamodzi, we believe in the power of business to liberate people. This belief informs our attitude and
            approach to how we conduct ourselves.
          </p>
          <div class="pt-5">
            <router-link to="/about" class="">
              <button class="btn rounded-pill pamodzi-red-background text-light font-weight-bold">
                Read More
              </button>
            </router-link>
          </div>
        </div>
        <div class="col-md-6"></div>
      </div>
      <div class="row">
        <div class="mx-auto">
          <a class="bi bi-arrow-down-circle-fill pamodzi-cream position-absolute icon bounce"
             v-on:click="scrollToAbout()"></a>
        </div>
      </div>
    </div>
  </div>
  <HomeAbout id="HomeAbout"></HomeAbout>
</template>

<script>
import HomeAbout from "@/components/home/HomeAbout";
import Typed from 'typed.js';

export default {
  name: "HomeATF",
  components: {HomeAbout},
  methods: {
    scrollToAbout() {
      document.getElementById('HomeAbout').scrollIntoView();
    }
  }
}
</script>

<style scoped lang="scss">
* {
  text-space: 0;
}

.text {
  position: absolute;
  top: 20%;
  letter-spacing: 0;
  width: 90%;
  margin: auto;
  left: 7%
}

.heading {
  font-size: 100px !important;
  line-height: 1;
  width: 90%;
  font-weight: 700;
}

.largeATF{
  background-image: linear-gradient(to right, rgba(33, 33, 32, 0.8), rgba(155, 29, 0, 0.5)),
  url(../../assets/home/homepage.png);
  //background-image: linear-gradient(to right, #212120, #9B1D00),
  //url(../../assets/images/contact/oldBuilding.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 20% 20%;
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .largeATF {
    visibility: visible;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .largeATF {
    visibility: visible;
  }


  .heading {
    font-size: 60px !important;
    line-height: 1;
    width: 90%;
    font-weight: 700;
  }

  .text {
    position: absolute;
    top: 20%;
    letter-spacing: 0;
    width: 90%;
    margin: auto;
    left: 7%
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .largeATF {
    visibility: hidden;
    height: 0 !important;

  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .largeATF {
    visibility: hidden;
    height: 0 !important;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .largeATF {
    visibility: hidden;
    height: 0 !important;;
  }
}


.vh-70 {
  height: 70%;
}

.width-80 {
  width: 80%;
}

.icon {
  bottom: 5vh;

  &:hover {
    color: #9B1D00;
  }
}


.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

</style>
