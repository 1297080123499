<template>
  <div class="">
    <div class="d-sm-none">
      <nav class="navbar navbar-expand-md navbar-light pamodzi-red-background">
        <router-link to="/" class="navbar-brand" href="#">
          <img src="../../assets/images/shared/logo.png" alt="Logo" />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <router-link
                to="/"
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                href="#"
                >Home <span class="sr-only"></span
              ></router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/about"
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                href="#"
                >About</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/team"
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                href="#"
                >Team</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/subsidiaries"
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                href="#"
                >Group</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/news"
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                href="#"
                >News</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/contact-us"
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                href="#"
                >Contact Us</router-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="d-none d-md-block navATF">
      <div class="">
        <nav class="navbar navbar-expand-lg navbar-dark">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
            <div class="col-6 d-flex justify-content-start">
              <router-link to="/">
                <a class="navbar-brand" href="#">
                  <img
                    :src="require('../../assets/images/shared/logo.png')"
                    alt="Brand"
                  />
                </a>
              </router-link>
            </div>
            <div class="col-6">
              <ul class="navbar-nav mr-auto mt-2 mt-lg-0 nav-ul">
                <li class="nav-item">
                  <router-link :class="colorNav" to="/">
                    Home
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :class="colorNav" to="/about">
                    About
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :class="colorNav" to="/team">
                    Team
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :class="colorNav" to="/subsidiaries">
                    Group
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :class="colorNav" to="/news">
                    News
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :class="colorNav" to="/contact-us">
                    <button
                      class="nav-link btn rounded-pill pamodzi-red-background text-white"
                    >
                      Contact Us
                    </button>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
// import NavATF from "./NavATF.vue";
export default {
  components: {},
  name: "Navbar",
  data() {
    return {
      route: "",
      colorNav: "text-white",
    };
  },
  methods: {
    routeCheck() {
      if (this.$route.path.includes("subsidiaries/") || this.$route.path.includes("about") || this.$route.path.includes("team/")) {
        this.colorNav = "text-dark";
      } else {
        this.colorNav = "text-white";
      }

      if(this.$route.path.includes("team/Sindisiwe-Dlamini")){
        this.colorNav = "text-white";
      }
    },
  },
  watch: {
    $route() {
      this.routeCheck();
    },
  },
  mounted() {
    this.routeCheck();
  },
};
</script>

<style scoped>
* {
  font-size: 15px;
}

button {
  font-size: 15px;
}

.mobile-nav {
  display: none;
}

.nav-ul {
  justify-content: space-around;
  display: flex;
  flex-grow: 100;
}

.navATF .navbar {
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.navbar-brand img {
  max-height: 50px;
}

.nav-link {
  color: white !important;
}

.navPage {
  position: absolute;
}
</style>
