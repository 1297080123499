<template>
  <div class="w-100">
    <div
      v-bind:style="{ backgroundColor: selectedNav.backgroundColor }"
      class="row "
    >
      <div
        class="col-md-6 p-0 my-auto pt-md-5 p-0 order-md-0 order-1 z-index-5 px-md-0 px-3 "
        :class="[
          selectedNav.id == 'contact-us'
            ? 'order-md-1 order-0 my-5'
            : 'order-md-0 order-1 my-auto',
        ]"
      >
        <div class="">
          <div class="p-md-5 p-3 ml-md-5 ml-0">
            <h1
              id="heading"
              v-bind:style="{ color: selectedNav.color }"
              v-html="selectedNav.header"
              class="atf-heading pa text-uppercase mt-md-n0 mt-n4"
            ></h1>
            <p
              v-if="selectedNav.position"
              v-bind:style="{ color: selectedNav.paragraphColor }"
              class="h3 pt-2"
            >
              {{ selectedNav.position }}
            </p>
            <p
              v-bind:style="{ color: selectedNav.paragraphColor }"
              class="pt-md-5 pt-3"
            >
              {{ selectedNav.paragraph }}
            </p>
            <!--            <router-link to="/about">-->
            <!--              <button-->
            <!--                  v-if="selectedNav.id == 'home'"-->
            <!--                  class="btn rounded-pill pamodzi-cream-backgropund pamodzi-red my-3 font-weight-bolder"-->
            <!--              >-->
            <!--                Contact Us-->
            <!--              </button>-->
            <!--            </router-link>-->
          </div>
        </div>
      </div>
      <div
        class="col-md-6"
        :class="[
          selectedNav.id == 'contact-us'
            ? 'order-md-0 order-1'
            : 'order-md-1 order-0',
        ]"
      >
        <img v-bind:src="selectedNav.img" alt="" class="img-fluid w-100" />
      </div>
    </div>
  </div>
  <!--  <div class="row row-01">-->
  <!--    <div class="col-md-6 col-sm-12 col-xs-12 col-one">-->
  <!--      &lt;!&ndash; <div-->
  <!--      v-bind:style="colOneStyle"-->
  <!--      class="col-md-6 col-sm-12 col-xs-12 col-one col-form"-->
  <!--    ></div> &ndash;&gt;-->
  <!--      <br/>-->
  <!--      <br/>-->
  <!--      <br/>-->
  <!--      <div class="row d-flex justify-content-end">-->
  <!--        <div class="col-sm-12 header-txt">-->
  <!--          <p v-html="selectedNav.header"></p>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="row ">-->
  <!--        <div class="col-sm-10 main-text">-->
  <!--          <p>-->
  <!--            {{ selectedNav.paragraph }}-->
  <!--          </p>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div-->
  <!--        class="col-md-6 col-sm-12 col-xs-12 col-two"-->
  <!--    ></div>-->
  <!--  </div>-->
</template>

<script>
export default {
  name: "NavATF",
  props: {
    routeId: String,
  },
  created() {
    this.route = this.$route.name;

    this.selectedNav = this.routes.filter((a) => a.id === this.routeId)[0];
    console.log(this.$route.name);
    console.log(this.selectedNav);
  },
  data() {
    return {
      route: "",
      routes: [
        {
          id: "home",
          backgroundColor: "#9B1D00",
          color: "#DBD0C0",
          headingColor: "#DBD0C0",
          paragraphColor: "#fff",
          img: require("../../assets/home/homepage.png"),
          header: "the power <br/> of business",
          paragraph:
            "At Pamodzi, we believe in the power of business to liberate people. This belief informs our attitude and approach to how we conduct ourselves.",
        },
        {
          id: "news",
          backgroundColor: "#212120",
          color: "#fff",
          paragraphColor: "#FFFFFF",
          img: require("@/assets/images/shared/newsATF.jpg"),
          header: "our news",
          paragraph:
            "At Pamodzi, we believe in the power of business to liberate people. This belief informs our attitude and approach to how we conduct ourselves.",
        },
        {
          id: "about",
          backgroundColor: "#D0D0D0",
          color: "#9B1D00",
          img: require("@/assets/images/about-us/aboutATF.jpg"),
          header: "Vision, Mission & Values",
          paragraph:
            "Our company history thus far bears testament to this fact. Through business we have been able to break the mental shackles imposed on us by Apartheid and our determination was to operate as a legitimate business during those strenuous times when black businesses suffered crippling restrictions, Pamodzi can proudly claim to have competed, toe-to-toe, with any business.",
        },
        {
          id: "team",
          backgroundColor: "#D0D0D0",
          color: "#9B1D00",
          img: require("../../assets/images/contact/oldBuilding.png"),
          header: "meet the team",
          paragraph:
            "Our company history thus far bears testament to this fact. Through business we have been able to break the mental shackles imposed on us by Apartheid and our determination was to operate as a legitimate business during those strenuous times when black businesses suffered crippling restrictions, Pamodzi can proudly claim to have competed, toe-to-toe, with any business.",
        },
        {
          id: "contact-us",
          backgroundColor: "#9B1D00",
          color: "#DBD0C0",
          img: require("../../assets/images/shared/temp-contact-us.jpg"),
          header: "Contact Us today",
          paragraph: "",
        },
        {
          id: "subsidiaries",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/Image.png"),
          header: "our subsidiary companies",
          paragraph:
            "In line with our brand ethos of using the spirit of business to liberate people at corporate level, we use the very same spirit to liberate industries. We strive to be a dominant player in each of the industries we operate in through the ownership of companies that are leaders in those sectors.",
        },
        {
          id: "Pamodzi Unique Engineering",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/pamodziGroupLogos/unique.png"),
          header: "Pamodzi Unique Engineering",
          paragraph:
            "Unique in every way, making unique technology pieces to build the economy.",
        },
        {
          id: "Pamodzi Smart",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/pamodziGroupLogos/smart.png"),
          header: "Pamodzi Smart",
          paragraph:
            "Convenience | Simplicity | Bankability | Modern | Accessibility",
        },
        {
          id: "Pamodzi Petroleum",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/pamodziGroupLogos/petroleum.jpg"),
          header: "Pamodzi Petroleum",
          paragraph: "Affordable service in motion",
        },
        {
          id: "Pamodzi Pannonia",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/pamodziGroupLogos/pannonia.png"),
          header: "Pamodzi Pannonia",
          paragraph:
            "Delivering precision engineering to discerning and demanding customers.",
        },
        {
          id: "Pamodzi Technologies",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/pamodziGroupLogos/tech.jpg"),
          header: "Pamodzi Technologies",
          paragraph: "Technology that drives business",
        },
        {
          id: "Pamodzi Green Energy",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/pamodziGroupLogos/greenEnergy.png"),
          header: "Pamodzi Green Energy",
          paragraph:
            "The future is green and Pamodzi Green energy is the future.",
        },
        {
          id: "Pamodzi Resources",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/pamodziGroupLogos/resources.png"),
          header: "Pamodzi Resources",
          paragraph: "Changing face of resources for future generations",
        },
        {
          id: "PRES",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/pamodziGroupLogos/pres.png"),
          header: "Pamodzi Revenue and Energy Solutions",
          paragraph: "",
        },
        {
          id: "Pamodzi FAW + Fleet Management",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/pamodziGroupLogos/fawLogo.png"),
          header: "Pamodzi FAW/ Fleet Management",
          paragraph: " ",
        },
        {
          id: "Pamodzi rail",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/pamodziGroupLogos/rail.png"),
          header: "Pamodzi rail",
          paragraph: "Online | on rail | rolling stock",
        },
        {
          id: "Phephani Tyre Tech",
          paragraphColor: "#FFFFFF",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/subsidiaries/pamodziGroupLogos/phephani.png"),
          header: "Phephani Tyre Tech",
          paragraph: "Safety is the only thing that matters",
        },
        {
          id: "Ndaba-Ntsele",
          paragraphColor: "#FFFFFF",
          position: "Executive Chairman",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/team/ndaba/ndabaNtsele1.jpg"),
          header: "Ndaba Ntsele",
          paragraph: "",
        },
        {
          id: "Erika-Toto-Tlaba",
          paragraphColor: "#FFFFFF",
          position: "Group HR",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/erika/erika.jpg"),
          header: "Erika Tlaba",
          paragraph: "",
        },
        {
          id: "Noluthando-Ntsele",
          paragraphColor: "#FFFFFF",
          position: "Group Legal/PRES CEO",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/thando/thando.jpg"),
          header: "<br/><br/>Noluthando Ntsele",
          paragraph: "",
        },
        {
          id: "Ayavuya-Madolo",
          paragraphColor: "#FFFFFF",
          position: "Group Sales and Marketing/GM Pamodzi Smart Stores",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/ayavuya/ayavuya.jpg"),
          header: "Ayavuya Madolo",
          paragraph: "",
        },
        {
          id: "Lucky-Mbatha",
          paragraphColor: "#FFFFFF",
          position: "Pamodzi Smart Store-General Manager",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/no-image/no-image.svg"),
          header: "Lucky Mbatha",
          paragraph: "",
        },
        {
          id: "Mthobisi-Mdluli",
          paragraphColor: "#FFFFFF",
          position: "Pamodzi Pannonia Precision Engineering-Managing Director",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/mthobisi/mthobisi.jpg"),
          header: "Mthobisi Mdluli",
          paragraph: "",
        },
        {
          id: "Mabora-Manamela",
          paragraphColor: "#FFFFFF",
          position:
            "PAMODZI FLEET – CEO/PAMODZI REVENUE AND ENERGY SOLUTIONS – GM",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/mabora/mabora.jpg"),
          header: "Mabora Manamela",
          paragraph: "",
        },
        {
          id: "Mothobisi-Mdluli-2",
          paragraphColor: "#FFFFFF",
          position: "Pamodzi Green Energy-Managing Director",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/mthobisi/mthobisi.jpg"),
          header: "Mthobisi Mdluli",
          paragraph: "",
        },
        {
          id: "Mothobisi-Mdluli-3",
          paragraphColor: "#FFFFFF",
          position: "Pamodzi Phephani Tyre Tech-Managing Director",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/mthobisi/mthobisi.jpg"),
          header: "Mthobisi Mdluli",
          paragraph: "",
        },
        {
          id: "Place-Holder",
          paragraphColor: "#FFFFFF",
          position: "Pamodzi Health Services",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/no-image/no-image.svg"),
          header: "Place Holder",
          paragraph: "",
        },
        {
          id: "Minnenhle-Khwela",
          paragraphColor: "#FFFFFF",
          position: "GROUP FINANCE MANAGEMENT",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/minnenhle/minnenhle.png"),
          header: "Minnenhle Khwela",
          paragraph: " ",
        },
        {
          id: "Limukani-Siziba",
          paragraphColor: "#FFFFFF",
          position: "GROUP BUSINESS ADMINISTRATOR",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/lumikani/limukani.png"),
          header: "Limukani Siziba",
          paragraph: " ",
        },
        {
          id: "Thandeka-Mthembu",
          paragraphColor: "#FFFFFF",
          position: "GROUP OFFICE MANAGER",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/thandeka/thandeka.png"),
          header: "Thandeka Mthembu",
          paragraph: " ",
        },
        {
          id: "Thami-Nompula",
          paragraphColor: "#FFFFFF",
          position: "CEO Pamodzi Phephani Tyre Tech",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/thami/thami.png"),
          header: "Thami Nompula",
          paragraph: " ",
        },
        {
          id: "Dupree-Vilakazi",
          paragraphColor: "#FFFFFF",
          position: "Director",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/dupree/dupree.jpg"),
          header: "Dupree Vilakazi",
          paragraph: " ",
        },
        {
          id: "Dupree-Vilakazi",
          paragraphColor: "#FFFFFF",
          position: "Director",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/dupree/dupree.jpg"),
          header: "Dupree Vilakazi",
          paragraph: " ",
        },
        {
          id: "Dupree-Vilakazi",
          paragraphColor: "#FFFFFF",
          position: "Director",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/dupree/dupree.jpg"),
          header: "Dupree Vilakazi",
          paragraph: " ",
        },
        {
          id: "Goodluck-Phumzile-Tshelane",
          paragraphColor: "#FFFFFF",
          position: "Executive Director",
          color: "#9B1D00",
          backgroundColor: "#212120",
          img: require("../../assets/images/team/phumzile-tselane/phumzile.jpg"),
          header: "Goodluck Phumzile Tshelane",
          paragraph: " ",
        },
        // {
        //   id: "Tshidi-Mokgabudi",
        //   paragraphColor: "#FFFFFF",
        //   position: "Audit and Risk Committee - Chairperson",
        //   color: "#9B1D00",
        //   backgroundColor: "#D0D0D0",
        //   img: require("../../assets/images/team/tshidi/tshidi.jpg"),
        //   header: "Tshidi Mokgabudi",
        //   paragraph: " ",
        // },
        {
          id: "Mothejwa-Sethunya",
          paragraphColor: "#FFFFFF",
          position: "Group HR",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/mothejwa/mothejwa.jpg"),
          header: "Mothejwa Sethunya",
          paragraph: " ",
        },
        {
          id: "Yang-Alex-Wang",
          paragraphColor: "#FFFFFF",
          position: "Project Development Officer",
          color: "#9B1D00",
          backgroundColor: "#D0D0D0",
          img: require("../../assets/images/team/yang-alex-wang/yang.jpg"),
          header: "Yang Alex Wang",
          paragraph: " ",
        }
      ],
      selectedNav: {},
      colOneStyle: {
        backgroundColor: "rgb(155, 29, 0)",
        color: "#DBD0C0",
      },
      colTwoStyle: {
        backgroundSize: "cover",
        backgroundColor: "#9B1D00",
      },
      rowStyle: {},
      currentNavUrl: {},
    };
  },
  methods: {
    getImage(obj) {
      if (obj.id == "contact-us") {
        return `url(${require("@/assets/images/shared/temp-spiral-01.jpg")}) `;
      }
      return obj.img;
    },
  },
  watch: {
    "$route.params": {
      handler: function(params) {
        if (this.$route.name === "subsidiaries") {
          if (params.id) {
            console.log("goto sub/", params.id);
            this.selectedNav = this.routes.filter((a) => a.id === params.id)[0];
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.z-index-5 {
  z-index: 5;
}

img {
  object-fit: cover;
  height: 100%;
}
</style>
